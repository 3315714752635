// @ts-check
import { createContext } from 'react';
import memoize from 'fast-memoize';
import { businessService, getServiceSetByServiceSetId } from './businesses';
import { differenceInHours } from 'date-fns';

export const PAYMENT_TYPE_CARD = 'card';
export const PAYMENT_TYPE_KLARNA = 'klarna';
export const PAYMENT_TYPE_GIROPAY = 'giropay';
export const PAYMENT_TYPE_BANCONTACT = 'bancontact';
export const PAYMENT_TYPE_APPLE_PAY = 'apple_pay';
export const PAYMENT_TYPE_GOOGLE_PAY = 'google_pay';
export const LOCAL_DEFAULT_PAYMENT_METHOD_KEY = 'default:paymentMethod';

export const OnlinePaymentDefaultContextData = {
	customerID: null,
	clientSecret: null,
	intentID: null,
	paymentIntentAmount: null,
	isPaymentIntentUpdating: false,
	intentData: null,
	paymentMethods: null,
	selectedPaymentMethod: null,
	defaultPaymentMethod: null,
	displayAddCard: false,
	getNewIntentId: true,
	redirectTokenError: null,
	newPaymentMethodFromElement: null,
	paymentMethodType: PAYMENT_TYPE_CARD,
	hasAcceptedSaveCard: null,
	consentedPaymentMethods: [],
	doesSelectedPaymentMethodNeedConsent: null,
	isSelectedPaymentLinkedToActiveAppointments: false,
	isCurrentCardOnSession: false
};

const OnlinePaymentContext = createContext(OnlinePaymentDefaultContextData);

export const OnlinePaymentContextProvider = OnlinePaymentContext.Provider;
export const OnlinePaymentContextConsumer = OnlinePaymentContext.Consumer;

export const cardImage = memoize(cardType => {
	let cardUrl = '';
	switch (cardType) {
		case 'visa':
			cardUrl =
				'https://res.cloudinary.com/planity/image/upload/v1570535435/payment/visa.png';
			break;
		case 'mastercard':
			cardUrl =
				'https://res.cloudinary.com/planity/image/upload/v1570535435/payment/mastercard.png';
			break;
		case 'amex':
			cardUrl =
				'https://res.cloudinary.com/planity/image/upload/v1570535434/payment/american.png';
			break;
		default:
			cardUrl =
				'https://res.cloudinary.com/planity/image/upload/v1570535435/payment/visa.png';
			break;
	}

	return cardUrl;
});

const addDataToService = (business, service, step) => {
	service.cancelRate =
		service.cancelRate || service.cancelRate === 0
			? service.cancelRate
			: business.settings.onlinePayment.lateCancellationRate;
	service.noshowRate =
		service.noshowRate || service.noshowRate === 0
			? service.noshowRate
			: business.settings.onlinePayment.noShowRate;
	service.lateCancellationDelay =
		business.settings.onlinePayment.lateCancellationDelay;

	return service;
};

export const parseServices = memoize((steps, business) => {
	if (steps.length > 0) {
		let isAtLeastOneServiceOnlinePaiementWithPrice = false;

		const services = steps.reduce(
			(acc, step) => {
				if (step.serviceId) {
					const service = businessService(business, step.serviceId);
					const serviceSet = getServiceSetByServiceSetId(
						business,
						service.serviceSetId
					);

					const isOnlinePayment =
						service.onlinePayment ||
						(service.onlinePayment === undefined && serviceSet.onlinePayment) ||
						(service.onlinePayment === undefined &&
							serviceSet.onlinePayment === undefined &&
							business.settings &&
							business.settings.onlinePayment &&
							business.settings.onlinePayment.onlinePayment);

					//Check if at least one service have a price
					if (
						service.prices &&
						(service.prices.default || service.prices.min) &&
						!service.prices.onQuotation &&
						isOnlinePayment
					) {
						isAtLeastOneServiceOnlinePaiementWithPrice = true;
					}

					if (
						isOnlinePayment &&
						service.prices &&
						(service.prices.default || service.prices.min) &&
						!service.prices.onQuotation
					) {
						acc.isOnline.push(addDataToService(business, service, step));
					} else {
						acc.remaining.push(addDataToService(business, service, step));
					}
				}
				return acc;
			},
			{ isOnline: [], remaining: [] }
		);

		if (isAtLeastOneServiceOnlinePaiementWithPrice) {
			services.isOnline = [...services.isOnline, ...services.remaining];
			services.remaining = [];
		} else {
			services.remaining = [...services.isOnline, ...services.remaining];
			services.isOnline = [];
		}

		return services;
	}
});

export const sequenceWithPaymentCanBeCancelled = ({ sequence, start }) => {
	return !Object.keys(sequence || {}).find(stepId => {
		const { lateCancellationDelay } = sequence[stepId];
		return differenceInHours(start, new Date()) < lateCancellationDelay;
	});
};

/* Stripe card errors */

export const EXPIRED_CARD = 'EXPIRED_CARD';
export const INCORRECT_NUMBER = 'INCORRECT_NUMBER';
export const INCORRECT_CVC = 'INCORRECT_CVC';
export const INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS';
export const INVALID_AMOUNT = 'INVALID_AMOUNT';
export const INVALID_CVC = 'INVALID_CVC';
export const INVALID_EXPIRY_MONTH = 'INVALID_EXPIRY_MONTH';
export const INVALID_EXPIRY_YEAR = 'INVALID_EXPIRY_YEAR';
export const INVALID_NUMBER = 'INVALID_NUMBER';
export const STOLEN_CARD = 'STOLEN_CARD';
export const GENERIC_DECLINE = 'GENERIC_DECLINE';
export const INCOMPLETE_NUMBER = 'INCOMPLETE_NUMBER';
export const INCOMPLETE_CVC = 'INCOMPLETE_CVC';
export const INCOMPLETE_EXPIRY = 'INCOMPLETE_EXPIRY';
export const INVALID_EXPIRY_YEAR_PAST = 'INVALID_EXPIRY_YEAR_PAST';
export const LOST_CARD = 'LOST_CARD';
export const PROCESSING_ERROR = 'PROCESSING_ERROR';
export const FRAUDULENT = 'FRAUDULENT';
export const PAYMENT_INTENT_AUTHENTICATION_FAILURE =
	'PAYMENT_INTENT_AUTHENTICATION_FAILURE';
export const SETUP_INTENT_AUTHENTICATION_FAILURE =
	'SETUP_INTENT_AUTHENTICATION_FAILURE';
export const INVALID_OWNER_NAME = 'INVALID_OWNER_NAME';
export const INCOMPLETE_NAME = 'INCOMPLETE_NAME';
export const CARD_NOT_SUPPORTED = 'CARD_NOT_SUPPORTED';
export const DUPLICATE_TRANSACTION = 'DUPLICATE_TRANSACTION';
export const NOT_PERMITTED = 'NOT_PERMITTED';
export const INVALID_NAME_FOR_USER_USING_KLARNA =
	'INVALID_NAME_FOR_USER_USING_KLARNA';
export const WHITELISTED_ERRORS_STRIPE = [
	EXPIRED_CARD,
	INCORRECT_NUMBER,
	INCORRECT_CVC,
	INSUFFICIENT_FUNDS,
	INVALID_AMOUNT,
	INVALID_CVC,
	INVALID_EXPIRY_MONTH,
	INVALID_EXPIRY_YEAR,
	INVALID_NUMBER,
	INVALID_NAME_FOR_USER_USING_KLARNA,
	STOLEN_CARD,
	GENERIC_DECLINE,
	INCOMPLETE_NUMBER,
	INCOMPLETE_CVC,
	INCOMPLETE_EXPIRY,
	INVALID_EXPIRY_YEAR_PAST,
	LOST_CARD,
	PROCESSING_ERROR,
	FRAUDULENT,
	PAYMENT_INTENT_AUTHENTICATION_FAILURE,
	SETUP_INTENT_AUTHENTICATION_FAILURE,
	INVALID_OWNER_NAME,
	INCOMPLETE_NAME,
	CARD_NOT_SUPPORTED,
	DUPLICATE_TRANSACTION,
	NOT_PERMITTED
].map(e => `stripe.errors.${e}`);

/* Parse stripe card errors */
export const isStripeError = result => {
	const stripeError =
		result?.error?.type === 'card_error' ||
		result?.error?.type === 'invalid_request_error' ||
		result?.error?.type === 'validation_error';

	return stripeError;
};
