// @ts-check
import React from 'react';
import { Localize } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './deposit_payment.modules.scss';
import { useTranslation } from '@planity/localization';
import { Radio } from '@planity/ui';
import { noop, PAYMENT_METHOD } from '@planity/helpers';
import credentials from '@planity/credentials';

const { USE_GIFT_VOUCHER_FOR_PAYMENTS_ENABLED, USE_CURE_FOR_PAYMENTS_ENABLED } =
	credentials;

export const PaymentChoice = ({
	totalAmount,
	depositRest,
	paymentMethod,
	setPaymentMethod,
	depositAmount,
	eligibleCures,
	allServicesAreCoveredByCures,
	isLoading
}) => {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		depositPayment: true
	});

	const shouldDisplayGiftVoucherOption = USE_GIFT_VOUCHER_FOR_PAYMENTS_ENABLED;
	const shouldDisplayCureVoucherOption =
		USE_CURE_FOR_PAYMENTS_ENABLED && eligibleCures.length > 0;
	const hasMultipleEligibleCures = eligibleCures.length > 1;

	const renderCureVoucherOption = () => (
		<Radio
			isChecked={paymentMethod === PAYMENT_METHOD.USE_CURE}
			label={t(`onlinePayment.how.cureVoucher.label`, {
				count: Number(hasMultipleEligibleCures)
			})}
			labelClassName={styles.label}
			name={'cure-voucher'}
			text={() => (
				<>
					{eligibleCures.map(cure => (
						<div className={styles.cureItem} key={cure.id}>
							<span className={styles.cureName}>{cure.cureName}</span>
							<span className={styles.remainingQuantityTitle}>
								{'-'}{' '}
								<Localize text='myAccount.cures.remainingQuantity.title' />
							</span>
							<span className={styles.remainingQuantityLabel}>
								<Localize
									args={{
										originalQuantity: cure.originalQuantity,
										quantity: cure.quantity
									}}
									text={'myAccount.cures.remainingQuantity.label'}
								/>
							</span>
						</div>
					))}
					<div>
						{t(`onlinePayment.how.cureVoucher.text`, {
							count: Number(hasMultipleEligibleCures)
						})}
					</div>
					{!allServicesAreCoveredByCures && (
						<div>
							<Localize
								text={'onlinePayment.how.cureVoucher.servicesNotCovered'}
							/>
						</div>
					)}
				</>
			)}
			textClassName={styles.precision}
			onChange={
				isLoading ? noop : () => setPaymentMethod(PAYMENT_METHOD.USE_CURE)
			}
		/>
	);

	const renderDepositOption = () => (
		<Radio
			isChecked={paymentMethod === PAYMENT_METHOD.PAY_DEPOSIT}
			label={t('onlinePayment.how.depositLabel')}
			labelClassName={styles.label}
			name={'partial-payment'}
			text={() => (
				<Localize
					args={{
						depositAmount,
						leftToPayAmount: depositRest
					}}
					text={'onlinePayment.how.depositText'}
				/>
			)}
			textClassName={styles.precision}
			onChange={
				isLoading ? noop : () => setPaymentMethod(PAYMENT_METHOD.PAY_DEPOSIT)
			}
		/>
	);

	const renderFullPaymentOption = () => (
		<Radio
			isChecked={paymentMethod === PAYMENT_METHOD.PAY_ALL}
			label={t('onlinePayment.how.allLabel')}
			labelClassName={styles.label}
			name={'full-payment'}
			text={() => (
				<Localize args={{ totalAmount }} text={'onlinePayment.how.allText'} />
			)}
			textClassName={styles.precision}
			onChange={
				isLoading ? noop : () => setPaymentMethod(PAYMENT_METHOD.PAY_ALL)
			}
		/>
	);

	const renderGiftVoucherOption = () => (
		<Radio
			isChecked={paymentMethod === PAYMENT_METHOD.USE_GIFT_VOUCHER}
			label={t('onlinePayment.how.giftVoucher.label')}
			labelClassName={styles.label}
			name={'gift-voucher'}
			text={() => (
				<Localize
					args={{ totalAmount }}
					text={'onlinePayment.how.giftVoucher.text'}
				/>
			)}
			textClassName={styles.precision}
			onChange={
				isLoading
					? noop
					: () => setPaymentMethod(PAYMENT_METHOD.USE_GIFT_VOUCHER)
			}
		/>
	);

	return (
		<div className={classes}>
			<span className={styles.title}>
				<Localize text={'onlinePayment.how.title'} />
			</span>
			<div className={styles.choices}>
				{shouldDisplayCureVoucherOption && renderCureVoucherOption()}
				{renderDepositOption()}
				{renderFullPaymentOption()}
				{shouldDisplayGiftVoucherOption && renderGiftVoucherOption()}
			</div>
		</div>
	);
};
