import { AlgoliaSearch } from '@planity/components';
import credentials from '@planity/credentials';
import {
	useLocalization,
	useLocalizedRoutes,
	useTranslation,
	AVAILABLE_LOCALES
} from '@planity/localization';
import { BottomLinks } from '../../ui';
import React, { useMemo } from 'react';
import styles from './districts_around.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';

export const DistrictsAround = ({ isLoading, getRefiner, baseSearch }) => {
	useStyles(styles);
	const { routes } = useLocalizedRoutes();
	const cx = classNames.bind(styles);
	const { t } = useTranslation();
	const { language, countryCode } = useLocalization();

	// Get the slug of the main locality to perform a fetch on Algolia
	const parentSlug = routes.catchAll({
		search: getRefiner(
			{ ...baseSearch, place: null },
			baseSearch.parentPlace,
			'place'
		)
	});

	const parentSlugWithoutLocale = useMemo(() => {
		const localeMatcher = new RegExp(
			AVAILABLE_LOCALES.map(locale => `^/?${locale}`).join('|'),
			'gm'
		);
		return parentSlug.pathname.replace(localeMatcher, '').toLowerCase();
	}, [parentSlug]);

	function getPath(object) {
		// A bit of magic here. A special case to handle the main locality, that should be alongside its district
		if (object.depth === 1)
			return routes.catchAll({
				search: getRefiner({ ...baseSearch, place: null }, object, 'place')
			});
		return routes.catchAll({
			search: getRefiner(baseSearch, object, 'place')
		});
	}
	return (
		<AlgoliaSearch
			attributesToHighlight={[]}
			attributesToRetrieve={'*'}
			countryCode={countryCode}
			filters={`objectID:"${parentSlugWithoutLocale}"`}
			index={credentials.SEO_DATA}
			language={language}
			localizeResults
		>
			{({ data }) => {
				if (!data?.[0] || isLoading) {
					return null;
				}

				// Remove current place from data
				const placesWithoutCurrent = data[0]?.places?.children?.filter(
					child => child.slug !== baseSearch.place.slug
				);

				// Here we have gathered the districts of the city.
				const { name } = data[0];
				return (
					<div>
						<BottomLinks
							className={cx({ linkWrapper: true })}
							getPath={getPath}
							getText={place => `${name} ${place?.preposition} ${place?.name}`}
							links={[baseSearch?.parentPlace]?.concat(
								placesWithoutCurrent || []
							)}
							title={t('category.relatedSearches')}
						/>
					</div>
				);
			}}
		</AlgoliaSearch>
	);
};
