export default {
	FIREBASE_API_KEY: 'AIzaSyCHDw2qEbdAnqiqlAwrmumbyw5k5qTySJQ',
	FIREBASE_AUTH_DOMAIN:
		'planity-dev5-a409-default-rtdb.europe-west1.firebasedatabase.app',
	FIREBASE_DATABASE_URL:
		'https://planity-dev5-a409-default-rtdb.europe-west1.firebasedatabase.app',
	FIREBASE_AVAILABILITIES_SHARD_URL:
		'https://planity-dev5-a409-availabilities.europe-west1.firebasedatabase.app',
	FIREBASE_AVAILABILITIES_SHARD_1_URL:
		'https://planity-dev5-a409-availabilities-1.europe-west1.firebasedatabase.app',
	FIREBASE_AVAILABILITIES_SHARD_2_URL:
		'https://planity-dev5-a409-availabilities-2.europe-west1.firebasedatabase.app',
	FIREBASE_PUBLIC_SHARD_URL:
		'https://planity-dev5-a409-public.europe-west1.firebasedatabase.app',
	FIREBASE_STORAGE_BUCKET: 'planity-dev5-a409.appspot.com',
	AWS_API_ENDPOINT:
		'https://ljzubt58h7.execute-api.eu-west-1.amazonaws.com/dev5',
	ALGOLIA_APP_ID: '7DO2GHWCPV',
	ALGOLIA_API_KEY: 'a1cc429ef15e7750cefa5f7f8552931d',
	ALGOLIA_PRODUCTION_APP_ID: 'DAY79MUBW3',
	ALGOLIA_PRODUCTION_API_KEY: '8ec84cdda274cec79b9ad155973bc864',
	ALGOLIA_PLACES_APP_ID: 'pl574WJIL1Q2',
	ALGOLIA_PLACES_API_KEY: '719fde67c8532799b0f99a4d2b454daa',
	GOOGLE_API_BROWSER_KEY: 'AIzaSyCyxxNBb7EQIO_GtaEubaSgXvD3c7jrgf8',
	HOST: 'dev5.planitydev.com',
	CLOUD_FUNCTIONS_DOMAIN:
		'https://europe-west1-planity-dev5-a409.cloudfunctions.net',
	MAPBOX_API_KEY:
		'pk.eyJ1IjoicGxhbml0eSIsImEiOiI4QU5kRy1jIn0.suHIBfZHkiiPnuVFhQSRMg',
	USER_APP_LINK: 'https://planity.onelink.me/6GTD/4ef6ae2e',
	PARANOID_AVAILABILITIES: ['saco'],
	STRIPE_API_KEY:
		'pk_test_517CtV2A69XfgDO9EgnwM1lmXWCYiaDscjjzSTaosTngmxavETTOmTtJKGdfVt2VgZ6cGBDoY14xYdPBiThKmn619008hNfQCsH',
	STRIPE_API_KEY_FR:
		'pk_test_517CtV2A69XfgDO9EgnwM1lmXWCYiaDscjjzSTaosTngmxavETTOmTtJKGdfVt2VgZ6cGBDoY14xYdPBiThKmn619008hNfQCsH',
	STRIPE_API_KEY_BE:
		'pk_test_51Lfk9VFCwhxbUJ7mSdwaFCrP38BgelUQPvOjK7zbpZGHIvdyVRkfpvreRVHeoees2df6titaLQEjUAtxQfscNPkc00clFCnAsf',
	STRIPE_API_KEY_DE:
		'pk_test_51L15sZE7ePEuIRmRVnUPGutdLBj8udBvUsP86xlxMvdklBgHOSP2E3b57cz9rAP1wtdYCh4vjFazd49CiqUNwxgU0003HcpCuk',
	DISPLAY_REVIEWS: true,
	HIDE_ESHOP: false,
	HIDE_GIFT_VOUCHER_BUTTON: false,
	ENABLE_FACEBOOK_LOGIN: false,
	ENABLE_GOOGLE_LOGIN: false,
	ENABLE_GITHUB_LOGIN: false,
	AVAILABLE_PROVIDERS: ['password'],
	ENABLE_USER_PAYS_FEES: true,
	PLANITY_FEE: 1.58,
	PLANITY_FLAT: 18,
	STRIPE_FEE: 0,
	STRIPE_FLAT: 0,
	ENABLE_CLICK_AND_COLLECT: 'true',
	ENABLE_CLICK_AND_SHOP: true,
	WOOTRIC_ACCOUNT_TOKEN: 'NPS-593dabed',
	WOOTRIC_NO_SURVEYED_COOKIES: false,
	WOOTRIC_SURVEY_IMMEDIATELY: false,
	ENABLE_NPS: false,
	ENABLE_ENRICHMENT: true,
	CATEGORIES_INDEX: 'categories',
	PLACES_INDEX: 'places',
	BUSINESSES_INDEX: 'businesses',
	BUSINESSES_BY_AVERAGE_DESC_INDEX: 'businesses_byAverage_desc',
	BUSINESSES_BY_AVERAGE_ASC_INDEX: 'businesses_byAverage',
	BUSINESSES_BY_RATING_ASC_INDEX: 'businesses_byRating',
	SEO_DATA: 'seo_data_migration',
	RECAPTCHA_SITE_KEY: '6LcKxagpAAAAANGG6wAW1kaVk0S7wz8JTHjgQQLh',
	RECAPTCHA_MACHINE_ARN:
		'arn:aws:states:eu-west-1:430034275215:stateMachine:sendPhoneNumberVerificationCodeRecaptchaProtectedDev5',
	DIDOMI_PUBLIC_KEY: '13d3ec9d-8fde-4b8f-a007-4e60d6965caf',
	DIDOMI_NOTICE_ID: 'Ur3qMWGt',
	DIDOMI_NOTICE_ID_WHITE_LABEL: 'xx4h7KWT',
	SAVE_CARD_CONTROL_ACTIVATION_DATE: 1692008207,
	GOOGLE_CONVERSION_ENDPOINT:
		'https://www.google.com/maps/conversion/debug/collect',
	GOOGLE_PARTNERID: 20000120
};
