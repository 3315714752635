import React from 'react';
import { ResetPassword } from '@planity/ui';
import { useTranslation } from '@planity/localization';

export const ResetPasswordForm = ({
	handleDispatchInputs,
	handleDispatchEvents,
	isPending,
	error,
	password,
	isPro,
	errorPassword
}) => {
	const { t } = useTranslation();
	const localizedText = {
		title: t(`resetPassword.title`),
		alert: t('resetPassword.alert'),
		label: t('resetPassword.label'),
		placeholder: t(`resetPassword.placeholder`),
		submit: t(`resetPassword.submit`),
		errorMessage: t(`resetPassword.errors.${error}`)
	};
	return (
		<div id='reset-password-form'>
			<ResetPassword
				error={error}
				errorPassword={errorPassword}
				handleDispatchEvent={handleDispatchEvents}
				handleDispatchInputs={handleDispatchInputs}
				isPro={isPro}
				localizedText={localizedText}
				password={password}
				pending={isPending}
			/>
		</div>
	);
};
