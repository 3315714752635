// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".districts_around-module_linkWrapper-ove5s{margin-bottom:44px}@media(min-width: 768px){.districts_around-module_linkWrapper-ove5s:last-of-type{margin-bottom:60px}}", "",{"version":3,"sources":["webpack://./app/components/search_page/districts_around/districts_around.module.scss"],"names":[],"mappings":"AAEA,2CACC,kBAAA,CAEA,yBACC,wDACC,kBAAA,CAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.linkWrapper {\n\tmargin-bottom: 44px;\n\n\t@media (min-width: #{styles.$tablet}) {\n\t\t&:last-of-type {\n\t\t\tmargin-bottom: 60px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"linkWrapper": "districts_around-module_linkWrapper-ove5s"
};
module.exports = ___CSS_LOADER_EXPORT___;
