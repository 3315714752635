/* eslint-disable react/jsx-no-literals */
import React from 'react';
import classNames from 'classnames/bind';
import styles from './impressum.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '@planity/ui';
import { Layout } from '../layout';

export const Impressum = () => {
	useStyles(styles);

	const classes = classNames.bind(styles)({
		impressum: true
	});
	return (
		<Layout
			breadcrumbsAtTop
			className={classes}
			crumbName={'impressum'}
			hasBackgroundColor
			hasFullWidth
			isHiddenOnMobile
			skipCoronavirusModal
		>
			<Container className={styles.container} size={'large'}>
				<div className={styles.textContainer}>
					<h1>Impressum </h1>

					<p>Planity GmbH</p>
					<p>Christophstraße 15-17</p>
					<p>50670 Köln</p>
					<p>
						<span>Telefon: </span>
						<a className={styles.link} href='tel:+491141872888'>
							0211-4187 2888
						</a>
					</p>
					<p>
						<span>E-Mail: </span>
						<a className={styles.link} href='mailto:kontakt@planity.com'>
							kontakt@planity.com
						</a>
					</p>

					<p>Geschäftsführer: Herr Antoine PUYMIRAT</p>
					<p>Registergericht: Amtsgericht Köln</p>
					<p>Registernummer: HRB 111877</p>

					<p>USt-IdNR gemäß § 27a Umsatzsteuergesetz: DE347881247</p>
					<br />

					<p>
						Inhaltlich Verantwortlicher gemäß § 18 MStV: Planity GmbH,
						Christophstraße 15-17, 50670 Köln, vertreten durch den
						Geschäftsführer Herrn Antoine Kosta Puymirat.
					</p>
					<br />

					<p>
						<span>
							Hinweis zum Streitbeilegungsverfahren gemäß Art. 14 Abs. 1
							ODR-Verordnung: Die Europäische Kommission stellt eine Plattform
							für außergerichtliche Online-Streitigkeiten (OS-Plattform) bereit,
							aufrufbar unter{' '}
						</span>
						<a
							className={styles.link}
							href={'https://ec.europa.eu/consumers/odr'}
						>
							https://ec.europa.eu/consumers/odr
						</a>
						.
					</p>
					<br />
					<p>
						Wir sind zur Teilnahme an einem Schlichtungsverfahren nicht
						verpflichtet und haben uns einer außergerichtlichen Streitbeilegung
						vor einer Verbraucherschlichtungsstelle nicht unterworfen.
					</p>
					<br />

					<p>
						Die vorliegenden Informationen gelten auch für Auftritte der Planity
						GmbH in sozialen Medien.
					</p>
				</div>
				<div className={styles.textContainer}>
					<h2>Haftungsausschluss</h2>

					<h3>Haftung für Inhalte</h3>

					<p>
						Gemäß § 7 Abs. 1 TMG sind wir als Diensteanbieter für eigene
						Informationen auf unseren Webseiten, die wir zur Nutzung
						bereithalten, nach den allgemeinen Gesetzen verantwortlich.
						Allerdings sind wir laut §§ 8 bis 10 TMG nicht verpflichtet,
						übermittelte oder gespeicherte fremde Informationen zu überwachen
						oder nach Umständen zu forschen, die auf eine rechtswidrige
						Tätigkeit hinweisen.
					</p>
					<br />

					<p>
						Eine Haftung ab dem Zeitpunkt der Kenntnis einer konkreten
						Rechtsverletzung bleibt unberührt. Sobald uns ein Rechtsverstoß
						bekannt wird, werden wir diesen Inhalt entfernen.
					</p>
					<br />

					<h3>Haftung für Links</h3>

					<p>
						Unsere Webseite enthält Links zu externen Webseiten Dritter, auf
						deren Inhalte wir keinen Einfluss haben. Aus diesem Grund können wir
						für diese Inhalte Dritter keine Verantwortung übernehmen. Nach
						unserer Überprüfung und zum Zeitpunkt der Verlinkung haben wir keine
						möglichen Rechtsverstöße erkannt. Sobald uns ein Rechtsverstoß
						bekannt wird, werden wir diesen Inhalt entfernen.
					</p>
					<br />

					<h3>Urheberrecht</h3>

					<p>
						Die Inhalte und Werke auf dieser Seite unterliegen dem deutschen
						Urheberrecht. Deren Verwendung bedarf unserer Zustimmung bzw. der
						Zustimmung des Rechteinhabers.
					</p>
					<br />

					<p>
						Soweit die Inhalte auf dieser Seite von Dritten erstellt wurden,
						werden die Urheberrechte Dritter beachtet und deren Inhalte als
						solche gekennzeichnet. Wenn Sie trotz dieser Maßnahmen auf eine
						Urheberrechtsverletzung aufmerksam werden, informieren Sie uns
						bitte. Sobald uns ein Rechtsverstoß bekannt wird, werden wir diesen
						Inhalt entfernen.
					</p>
				</div>
			</Container>
		</Layout>
	);
};
